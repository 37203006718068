.backdrop__filter {
  backdrop-filter: blur(19px);
  -webkit-backdrop-filter: blur(19px);
  background: rgba(255, 255, 255, 0.3);
}
.hero_blur_div_button {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 2px;
  color: #ffffff;
}

.backdrop__filter__2 {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 5px;
  z-index: 10;
}

.hideDiv {
  display: none;
}
