@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Aulire-Regular;
  src: url(../src/assets/fonts/Auliare-Regular.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: Article-regular;
  src: url(../src/assets/fonts/Article-regular.woff) format('woff');
  font-display: swap;
}
body {
  font-family: Article-regular;
  font-size: 16px;
  line-height: 24px;
  color: #001923;
}

/* old pages */

.input__style {
  padding: 16px;
  width: 224px;
  height: 48px;
  box-sizing: border-box;
  border: 1px solid #dadbd8;
}
.YourPlanInfoClass {
  border-radius: 5px;
  background: #23b1ec;
  padding: 2px 7px;
  color: aliceblue;
}

.Custom__Input__Box {
  border: 0;
  margin: 0px 6px;
  width: 150px;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
}

.__signature__button1 {
  border: 0.2px solid black;
  border-radius: 4px;
  background-color: #23b1ec;
  font-size: 20px;
  margin-top: 20px;
  margin-right: 5px;
  padding: 1px 2px;
  z-index: 50;
}

.__signature__button2 {
  border: 0.2px solid black;
  border-radius: 4px;
  background-color: #23b1ec;
  font-size: 20px;
  color: green;
  margin-right: 5px;
  margin-top: 20px;
  padding: 1px 8px;
  cursor: pointer;
  z-index: 50;
}

.box_shadow_universal {
  box-shadow: 0px 4px 30px 0px #17282f1a;
}

@media all and (max-width: 1023px) {
  .input__style {
    width: 288px;
    height: 33px;
    background: #f5f6f6;
    border-radius: 5px;
  }
  .YourPlanInfoClass {
    border-radius: 2px;
    background: #23b1ec;
    padding: 0px 0px;
    color: aliceblue;
  }
  .__signature__button1 {
    border: 0.2px solid black;
    border-radius: 2px;
    background-color: #23b1ec;
    font-size: 20px;
    margin-top: 12px;
    margin-right: 5px;
    padding: 1px 2px;
    z-index: 50;
  }

  .__signature__button2 {
    border: 0.2px solid black;
    border-radius: 2px;
    background-color: #23b1ec;
    font-size: 20px;
    color: green;
    margin-right: 5px;
    margin-top: 12px;
    padding: 1px 8px;
    cursor: pointer;
    z-index: 50;
  }
  .Custom__Input__Box {
    border: 0;
    margin: 0px 0px;
    width: 100px;
    outline: 0;
    background: transparent;
    border-bottom: 0.8px solid black;
  }
  .box_shadow_universal {
    box-shadow: 0px 0px 0px 0px white;
  }
}

/*! loading  */
.spinner {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: black;
}

.loader {
  border: 5px solid #23b1ec; /* Light grey */
  border-top: 5px solid #f5f6f6; /* Blue */
  border-bottom: 5px solid #f5f6f6; /* Blue */
  border-radius: 1%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* //! annimations*/

.elementToFadeInAndOut {
  -webkit-animation: fadeinout 1s linear forwards;
  -moz-animation: fadeinout 1s linear forwards;
  -o-animation: fadeinout 1s linear forwards;
  animation: fadeinout 1s linear forwards;
}

@-webkit-keyframes fadeinout {
  0%,
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.image101 {
  animation: anim2 15s ease-in-out 2s infinite both;
  -webkit-animation: anim2 15s ease-in-out 2s infinite both;
  -moz-animation: anim2 15s ease-in-out 2s infinite both;
  -o-animation: anim2 15s ease-in-out 2s infinite both;
}
.image100 {
  animation: anim 15s ease-in-out 2s infinite both;
  -webkit-animation: anim 15s ease-in-out 2s infinite both;
  -moz-animation: anim 15s ease-in-out 2s infinite both;
  -o-animation: anim 15s ease-in-out 2s infinite both;
}
.testimonials__anim {
  animation: anim3 20s ease-out 2s infinite;
  -webkit-animation: anim3 20s ease-out 2s infinite;
  -moz-animation: anim3 20s ease-out 2s infinite;
  -o-animation: anim3 20s ease-out 2s infinite;
}
.testimonials__anim1 {
  animation: anim3 20s ease-out 2s infinite;
  -webkit-animation: anim3 20s ease-out 2s infinite;
  -moz-animation: anim3 20s ease-out 2s infinite;
  -o-animation: anim3 20s ease-out 2s infinite;
}
.testimonials__anim2 {
  animation: anim3 20s ease-out 2s infinite;
  -webkit-animation: anim3 20s ease-out 2s infinite;
  -moz-animation: anim3 20s ease-out 2s infinite;
  -o-animation: anim3 20s ease-out 2s infinite;
}

@keyframes anim {
  0% {
    width: 40px;
  }
  10% {
    width: 896px;
  }
  30% {
    width: 896px;
  }
  40% {
    width: 40px;
  }
  60% {
    width: 40px;
  }
  70% {
    width: 896px;
  }
  90% {
    width: 896px;
  }
  100% {
    width: 40px;
  }
}
@-webkit-keyframes anim {
  0% {
    width: 40px;
  }
  10% {
    width: 896px;
  }
  30% {
    width: 896px;
  }
  40% {
    width: 40px;
  }
  60% {
    width: 40px;
  }
  70% {
    width: 896px;
  }
  90% {
    width: 896px;
  }
  100% {
    width: 40px;
  }
}

@keyframes anim2 {
  0% {
    width: 896px;
  }
  10% {
    width: 40px;
  }
  30% {
    width: 40px;
  }
  40% {
    width: 896px;
  }
  60% {
    width: 896px;
  }
  70% {
    width: 40px;
  }
  90% {
    width: 40px;
  }
  100% {
    width: 896px;
  }
}

@-webkit-keyframes anim2 {
  0% {
    width: 896px;
  }
  10% {
    width: 40px;
  }
  30% {
    width: 40px;
  }
  40% {
    width: 896px;
  }
  60% {
    width: 896px;
  }
  70% {
    width: 40px;
  }
  90% {
    width: 40px;
  }
  100% {
    width: 896px;
  }
}

@keyframes anim3 {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-390px);
  }
  30% {
    transform: translateY(-390px);
  }
  40% {
    transform: translateY(-775px);
  }
  60% {
    transform: translateY(-775px);
  }
  70% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes anim3 {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-390px);
  }
  30% {
    transform: translateY(-390px);
  }
  40% {
    transform: translateY(-775px);
  }
  60% {
    transform: translateY(-775px);
  }
  70% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

.image101__mobile {
  animation: mobile__anim1 10s ease-out 3s infinite;
  -webkit-animation: mobile__anim1 10s ease-out 3s infinite;
  -moz-animation: mobile__anim1 10s ease-out 3s infinite;
  -o-animation: mobile__anim1 10s ease-out 3s infinite;
}

@keyframes mobile__anim1 {
  0% {
    transform: translateX(2px);
  }
  7% {
    transform: translateX(-350px);
  }
  45% {
    transform: translateX(-350px);
  }
  52% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(2px);
  }
}

@-webkit-keyframes mobile__anim1 {
  0% {
    transform: translateX(2px);
  }
  15% {
    transform: translateX(-350px);
  }
  45% {
    transform: translateX(-350px);
  }
  60% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(2px);
  }
}

.fixed__div__centered {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
}
